<template>
  <div class="wrapper">
    <div class="container">
      <div class="divider">
        <div class="header-title">
          <h2>Input Plan Code</h2>
        </div>
        <div class="content">
          <input
            class="search"
            v-model.trim="planCode"
            type="text"
            name="plancode"
            placeholder="E.g DEPED-XXX"
            :class="{
              error: !isNext && isMessageShow,
            }"
          />
          <span style="color: blue; font-size: 12px"
            >Please enter selected Plan Code presented by agent</span
          >
          <div class="loading-container">
            <div class="btn-search" @click="searchHandler()">
              {{ isLoading ? null : "Select Plan code" }}
            </div>
            <app-loading v-if="isLoading" />
          </div>
          <span v-if="isMessageShow" class="message">
            {{ resMessage }} <verifiedSVG v-if="isNext" /> <sadSVG v-else />
          </span>
        </div>
        <div class="pagination">
          <div class="btn-action">
            <input
              type="button"
              value="Back"
              @click="$emit('actionPage', 'AgentProfile')"
            />
            <input type="button" value="Next" @click="nextHandler()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import verifiedSVG from "@/components/svgs/verifiedSVG.vue";
import sadSVG from "@/components/svgs/sadSVG.vue";
import ApplicationData from "@/data/ApplicationData";
import { ref, inject, onMounted } from "vue";
export default {
  components: {
    verifiedSVG,
    sadSVG,
  },
  setup(props, { emit }) {
    const store = inject("$store");
    const planCode = ref(null);
    const { planInfo, fetchPlanItems } = ApplicationData();
    const isLoading = ref(false);
    const isMessageShow = ref(false);
    const isNext = ref(false);
    const resMessage = ref(null);
    const planDetails = ref([]);
    onMounted(() => {
      if (store.state.ifRetrievedData) {
        planCode.value = store.state.formData.plancode;
        isNext.value = true;
        isMessageShow.value = true;
        resMessage.value = "Plan code successfully verified";
        planDetails.value = store.state.planDetails;
        store.state.planDetails = planDetails.value;
      } else {
        planCode.value = null;
        isNext.value = false;
        isMessageShow.value = false;
        resMessage.value = null;
      }
    });
    const searchHandler = async () => {
      isLoading.value = true;
      if (planCode.value) {
        planInfo.value = await fetchPlanItems(planCode.value);
      }

      isMessageShow.value = false;
      setTimeout(() => {
        isLoading.value = false;
        if (planInfo.value.length) {
          isNext.value = true;
          isMessageShow.value = true;
          resMessage.value = "Plan code successfully verified";
          planDetails.value = planInfo.value[0];
          store.state.planDetails = planInfo.value[0];
        } else {
          isMessageShow.value = true;
          isNext.value = false;
          resMessage.value =
            "Plan code not valid, Please re-enter the correct plan code";
          planCode.value = null;
        }
      }, 3000);
    };
    const nextHandler = () => {
      isNext.value
        ? emit("actionPage", "PlanDetails", planDetails.value)
        : null;
    };
    return {
      planCode,
      planInfo,
      searchHandler,
      isLoading,
      isMessageShow,
      isNext,
      resMessage,
      planDetails,
      nextHandler,
      fetchPlanItems,
    };
  },
};
</script>
<style scoped>
.divider {
  display: flex;
  flex-direction: column;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.content .search {
  height: 40px;
  width: 240px;
}
.content .btn-search {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fa7319;
  width: 150px;
  color: #fff;
  padding: 8px 10px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 400;
  gap: 4px;
  height: 26px;
}
.content .message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 5px;
  font-size: 13px;
}
.content img {
  height: 40px;
  width: 40px;
}
.pagination {
  margin: 20px 30px;
}
.pagination .btn-action {
  display: flex;
  justify-content: space-between;
}
</style>
