import { createApp } from "vue";
import App from "./App.vue";
import axios from "./utils/axios";
import router from "./router";
import store from "./store";
import Swal from "sweetalert2";
import moment from "moment";
import "./assets/css/styles.css";
import "./assets/css/table.css";
import ClientLayout from "./components/common/ClientLayout.vue";

import HeroLoading from "./components/common/HeroLoading.vue";
const app = createApp(App);
app.component("app-loading", HeroLoading);
app.component("client-layout", ClientLayout);
app.use(store);
app.use(router);
app.provide("$axios", axios);
app.provide("$router", router);
app.provide("$store", store);
app.provide("$swal", Swal);
app.provide("$moment", moment);
app.mount("#app");
