import axios from "axios";
import SecretData from "@/utils/secret/secretKey";
const { generateSecretKey } = SecretData();
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.request.use(
  function (config) {
    let secretDecryptKey = generateSecretKey();
    config.headers["x-api-key"] = secretDecryptKey;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
