<template>
  <div class="wrapper">
    <div class="container">
      <div class="divider">
        <div class="header-profile">
          <img class="card" :src="require('@/assets/images/ims-vcard.png')" />
          <h3>{{ $store.state.planDetails.PlanCode }}</h3>
          <div class="btn-status">{{ premium }}</div>
        </div>
        <div class="card-container">
          <div
            class="card-profile"
            :class="
              !$store.state.planDetails.LifeBen1
                ? 'withoutInsurance'
                : 'withInsurance'
            "
          >
            <div class="card-one">
              <heartSVG />
              <p>
                <span class="title">Hospitalization Coverage</span>
                <br />
                <span class="sub-title"
                  >up to
                  <b>{{
                    pesoFormat($store.state.planDetails.MaxCoverage)
                  }}</b></span
                >
                <br />
                <span>Annual Benefit Limit</span>
              </p>
            </div>
            <div class="card-two" v-if="$store.state.planDetails.LifeBen1">
              <securitySVG />
              <p>
                <span class="title">Insurance</span>
                <br />
                <span class="sub-title">
                  <b>{{
                    pesoFormat($store.state.planDetails.LifeBen1)
                  }}</b></span
                >
                <br />
                <span>Coverage </span>
              </p>
            </div>
          </div>
          <span class="provider-link">
            <a href="https://iwc.com.ph/services/providers" target="_blank">
              List of Accredited Hospitals & Clinics</a
            ></span
          >
        </div>

        <div class="overview-plan">
          <div class="title">
            <h2>Benefits</h2>
          </div>
          <div class="content">
            <div class="category">
              <h4>In-patient (on top of Philhealth)</h4>

              <div class="items">
                <li>
                  <checkSVG />
                  <span>
                    {{ roomBoard }}
                    room accomodation</span
                  >
                </li>
                <li>
                  <checkSVG />
                  <span>
                    Maximum of
                    {{ pesoFormat($store.state.planDetails.IllnessPerYear) }}
                    per illness per confinement per year.</span
                  >
                </li>
              </div>
            </div>
            <div class="category">
              <h4>Out-patient</h4>
              <div class="items">
                <li>
                  <checkSVG />
                  <span>
                    {{ pesoFormat($store.state.planDetails.PerQuarter_OP) }} per
                    quarter.</span
                  >
                </li>
                <li>
                  <span style="padding-left: 15px" v-show="false">
                    <b>UNLIMITED</b> IN ANY IWC OWNED and PARTNERED CLINICS.
                    <a
                      href="https://iwc.com.ph/services/providers"
                      target="_blank"
                      >(List)</a
                    >
                  </span>
                </li>
                <li style="margin-left: 20px">
                  <checkSVG />
                  <span>Consultation.</span>
                </li>
                <li style="margin-left: 20px">
                  <checkSVG />
                  <span>Laboratory Procedures </span>
                </li>
                <li style="margin-left: 20px">
                  <ul>
                    <li>Physcial Examination</li>
                    <li>Complete Blood Count (CBC)</li>
                    <li>Urinalysis</li>
                    <li>Fecalysis</li>
                    <li>Chest X-ray</li>
                    <li>Etc.</li>
                  </ul>
                </li>
              </div>
            </div>
            <div class="category">
              <h4>Dental Care</h4>
              <div class="items">
                <li>
                  <ul>
                    <li>
                      1. Free & Unlimited consultation & dental examinations
                    </li>
                    <li>
                      2. Simple tooth extraction, except surgery for impaction
                      (unlimited)
                    </li>
                    <li>
                      3. Temporary fillings; which can be availed after four (4)
                      months of active membership (one tooth per quarter)
                    </li>
                    <li>
                      4. Twice a year oral prophylaxis (mild to moderate) which
                      can be availed of after three (3) months of membership
                      (premium paid)/ succeeding may be availed of six (6)
                      months thereafter
                    </li>
                  </ul>
                </li>
                <li></li>
              </div>
            </div>
            <div class="category" v-if="$store.state.planDetails.LifeBen1">
              <h4>Insurance Coverage</h4>
              <div class="items">
                <li>
                  <checkSVG />
                  <span
                    >{{
                      pesoFormat($store.state.planDetails.LifeBen1)
                    }}
                    Life</span
                  >
                </li>
                <li>
                  <checkSVG />
                  <span>
                    {{ pesoFormat($store.state.planDetails.AddBen1) }} Accident
                    Death</span
                  >
                </li>
                <li>
                  <checkSVG />
                  <span>
                    {{
                      pesoFormat($store.state.planDetails.unprovokedMurderben1)
                    }}
                    Unprovoked Murder and Assault (UMA)</span
                  >
                </li>
                <li>
                  <checkSVG />
                  <span>
                    {{ pesoFormat($store.state.planDetails.tibBen) }}
                    Terminal Illness
                  </span>
                </li>
              </div>
            </div>
          </div>
        </div>
        <div class="card-confirmation">
          <h5>
            Please retype
            <b>{{ pesoFormat($store.state.planDetails.Premium) }}</b> to confirm
            amount of premium to be paid
          </h5>
          <input
            class="input-amount"
            v-model.number="confirmPremium"
            type="number"
            name="plancode"
            :class="{
              error:
                confirmPremium !== $store.state.planDetails.Premium &&
                confirmPremium,
            }"
            @input="confirmHandler"
          />
          <span v-if="isMessageShow" class="message">
            {{ resMessage }}
          </span>
        </div>
        <div class="pagination">
          <div class="btn-action">
            <input
              type="button"
              value="Back"
              @click="$emit('actionPage', 'PlanType')"
            />
            <input type="button" value="Next" @click="nextHandler()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import heartSVG from "@/components/svgs/heartSVG.vue";
import securitySVG from "@/components/svgs/securitySVG.vue";
import checkSVG from "@/components/svgs/checkSVG.vue";
import { computed, inject, onMounted, ref } from "vue";
import validationAlert from "@/utils/reusables/SwalAlert";
import ApplicationData from "@/data/ApplicationData";
export default {
  components: {
    heartSVG,
    securitySVG,
    checkSVG,
  },
  setup(props, { emit }) {
    const { dataPrivacy } = validationAlert();
    const { fetchValueAddedBenefit } = ApplicationData();
    const store = inject("$store");
    const confirmPremium = ref(0);
    const isMessageShow = ref(false);
    const resMessage = ref(null);
    const valueAdded = ref(null);
    onMounted(() => {
      if (store.state.ifRetrievedData) {
        confirmPremium.value = store.state.planDetails.Premium;
      } else {
        confirmPremium.value = 0;
      }
    });
    const premium = computed(() => {
      let formatPremium = store.state.planDetails.Premium.toLocaleString(
        "en-PH",
        {
          style: "currency",
          currency: "PHP",
        }
      );
      return `${formatPremium}/${PaymentMethod.value} Premium`;
    });
    const pesoFormat = (val) => {
      let modified = val.toLocaleString("en-PH", {
        style: "currency",
        currency: "PHP",
      });
      return `${modified}`;
    };
    const confirmHandler = () => {
      let selectedPremium = store.state.planDetails.Premium;
      if (confirmPremium.value === selectedPremium) {
        isMessageShow.value = false;
      } else {
        isMessageShow.value = true;
        resMessage.value = "Premium does not match";
      }
    };
    const nextHandler = () => {
      let selectedPremium = store.state.planDetails.Premium;

      if (confirmPremium.value === selectedPremium) {
        isMessageShow.value = false;
        store.commit("formDataHandler");
        if (!store.state.ifRetrievedData) {
          dataPrivacy();
        }
        emit("actionPage", "ClientInformation");
      } else {
        isMessageShow.value = true;
        resMessage.value = "Premium does not match";
      }
    };
    const roomBoard = computed(() => {
      let data = store.state.planDetails.RoomAndBoard;
      let res;

      if (data === "W") {
        res = "WARD";
      }
      if (data === "P") {
        res = "PRIVATE";
      }
      if (data === "SP") {
        res = "SEMI-PRIVATE";
      }
      if (data === "SR") {
        res = "SUIT ROOM";
      }
      return res;
    });
    const PaymentMethod = computed(() => {
      let data = store.state.planDetails.PaymentMethod;
      let res;
      if (data === "A") {
        res = "Annual";
      }
      if (data === "M") {
        res = "Monthly";
      }
      if (data === "Q") {
        res = "Quarterly";
      }
      if (data === "S") {
        res = "Semi-annual";
      }
      return res;
    });

    return {
      roomBoard,
      premium,
      pesoFormat,
      nextHandler,
      confirmPremium,
      isMessageShow,
      resMessage,
      valueAdded,
      PaymentMethod,
      dataPrivacy,
      confirmHandler,
      fetchValueAddedBenefit,
    };
  },
};
</script>
<style scoped>
.divider {
  display: flex;
  flex-direction: column;
}
.header-profile {
  color: var(--light-color);
  min-height: 370px;
  background-color: var(--bg-dark-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.header-profile .card {
  margin-top: 40px;
  height: 230px;
  width: 340px;
}
.header-profile .btn-status {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  min-width: 125px;
  color: var(--light-color);
  padding: 8px 14px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 600;
}
.card-container {
  min-height: 220px;
  background-color: var(--secondary-color);
}
.card-profile {
  display: grid;
  gap: 10px;
}
.withInsurance {
  grid-template-columns: 1fr 1fr;
}
.withoutInsurance {
  grid-template-columns: 1fr;
}
.card-profile .card-one,
.card-profile .card-two {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  text-align: center;
  color: var(--light-color);
}
.card-profile .card-one p,
.card-profile .card-two p {
  line-height: 22px;
  padding: 8px 6px;
  font-size: 12px;
}
.card-profile .card-one p .title,
.card-profile .card-two p .title {
  font-weight: 500;
  font-size: 15px;
}
.card-profile .card-one p .sub-title,
.card-profile .card-two p .sub-title {
  font-weight: 400;
  font-size: 12px;
}
.card-profile .card-one p .sub-title b,
.card-profile .card-two p .sub-title b {
  font-size: 15px;
}
.overview-plan {
  margin: 28px 20px;
}
.overview-plan .title {
  padding-bottom: 20px;
}
.overview-plan .title h2 {
  color: var(--accent-color);
  font-weight: 700;
}
.overview-plan .content {
  padding: 2px 10px;
}
.overview-plan .content h4 {
  color: var(--secondary-color);
}
.overview-plan .content .category .items {
  padding: 16px 0px;
}
.overview-plan .content .category .items li {
  font-size: 14px;
  font-family: var(--primary-font);
  color: var(--dark-color);
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.overview-plan .content .category .items li ul {
  margin: 0px 10px;
}
.card-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
}
.card-confirmation .input-amount {
  height: 45px;
  width: 240px;
  text-align: right;
}
.pagination .btn-action {
  display: flex;
  justify-content: space-between;
}
.invalid {
  color: var(--error-color);
  font-size: 14px;
}
.provider-link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  padding-top: 10px;
}
.provider-link a {
  font-weight: 600;
  color: var(--light-color);
  font-style: italic;
}
.indent {
  margin-left: 15px;
}
</style>
