<template>
  <div class="wrapper">
    <div class="container" v-if="!isLoading">
      <div class="divider">
        <div class="header-profile">
          <div class="image">
            <img :src="agentInfo.profile" alt="Localhost Image" />
          </div>
          <div
            class="status"
            :class="agentInfo.Status === 'A' ? 'active' : 'inactive'"
          >
            {{ agentInfo.Status === "A" ? "Active" : "Inactive" }}
          </div>
          <span class="title"> Status of Agent</span>
          <div class="name">
            {{ agentInfo.agentname }}
          </div>
        </div>
        <div class="content-profile">
          <div class="id-card">
            <img :src="agentInfo.idCard" />
          </div>
        </div>
        <div class="pagination">
          <div class="btn-action">
            <input
              type="button"
              value="Next"
              @click="$emit('actionPage', 'PlanType')"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading">
      <img :src="require('@/assets/images/loading.gif')" />
    </div>
  </div>
</template>
<script>
import ApplicationData from "@/data/ApplicationData";
import { onMounted, ref, inject } from "vue";
import { useRoute } from "vue-router";
import SecretData from "@/utils/secret/secretKey";
export default {
  components: {},
  setup() {
    const store = inject("$store");
    const route = useRoute();
    const agentCode = ref(null);
    const agentProfile = ref(null);
    const agentCard = ref(null);
    const { agentInfo, fetchAgentItems, fetchAgentDataFile } =
      ApplicationData();
    const { generateSecretKey } = SecretData();
    const isLoading = ref(true);
    onMounted(async () => {
      generateSecretKey();
      agentCode.value = route.params.id;
      try {
        const data = await fetchAgentItems(agentCode.value);
        agentInfo.value = data.find((item) => {
          agentCode.value = Number(agentCode.value);
          return item.Agentcode === agentCode.value;
        });
        store.state.agentDetails = agentInfo.value;

        isLoading.value = false;
      } catch (error) {
        console.error(error);
      }
    });
    return {
      agentCode,
      agentInfo,
      fetchAgentItems,
      fetchAgentDataFile,
      agentProfile,
      agentCard,
      isLoading,
    };
  },
};
</script>
<style scoped>
.divider {
  display: flex;
  flex-direction: column;
}
.header-profile {
  background-color: var(--bg-dark-color);
  color: var(--light-color);
  max-height: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding-bottom: 20px;
}
.header-profile .image {
  margin-top: 60px;
}
.header-profile .image img {
  height: 151px;
  width: 151px;
}
.header-profile .status {
  height: 16px;
  width: 85px;
  padding: 14px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-weight: 600;
}
.header-profile .title {
  margin-top: -10px;
  font-size: 14px;
}
.active {
  background-color: #2c952a;
}
.inactive {
  background-color: #ce3631;
}
.header-profile .name {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
}
.content-profile {
  min-height: 256px;
}
.content-profile .id-card {
  margin: 30px 0px 40px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-profile .id-card img {
  height: 190px;
  width: 340px;
}
.loading {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.pagination .btn-action {
  display: flex;
  justify-content: flex-end;
}
</style>
