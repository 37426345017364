<template>
  <div class="wrapper">
    <div class="logo">
      <img src="../../../assets/iwc-logo.png" />
    </div>
    <div class="container">
      <h1 class="header">{{ title }}</h1>
      <div class="input-wrapper">
        <input
          v-for="i in OTP_LENGTH"
          :key="`input-el-${i}`"
          :ref="addInputEls"
          :id="`input-el-${i}`"
          type="text"
          pattern="[0-9]*"
          @input="handleInputElsInput($event, i - 1)"
          :class="{ error: isError }"
        />
      </div>
      <h5 class="sub-title">Please enter your 6 digit reference code above.</h5>
      <div class="action-button">
        <input
          class="submit"
          type="button"
          value="Submit"
          @click="submitHandler"
        />
        <input
          class="cancel"
          type="button"
          value="Cancel"
          @click="cancelHandler"
        />
      </div>
      <div class="footer" v-if="isError">
        <span>
          {{ isErrorMessage }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted, inject } from "vue";
import RetrieveData from "@/data/client_portal/Client";
export default {
  setup() {
    // State
    const { retrieveDataHandler, isError, isErrorMessage } = RetrieveData();
    const router = inject("$router");
    const otpValue = ref(Array.from({ length: 6 }, () => ""));
    const isInProgress = ref(false);
    const inputEls = ref([]);
    const title = ref("Retrieve Account Information");
    const OTP_LENGTH = ref(6);
    // Lifecycle hook
    onMounted(() => {
      const firstEl = inputEls.value[0];
      if (firstEl) {
        firstEl.focus();
      }
    });

    // Computed
    const otpValueAfterJoin = computed(() => {
      return otpValue.value.join("").replace(/[^0-9]/g, "");
    });

    const isOtpValueSomeEmpty = computed(() => {
      return otpValue.value.some((v) => v !== 0 && !v);
    });

    // Methods
    const handleInputElsInput = (e) => {
      isError.value = false;
      isErrorMessage.value = null;
      const val = e.target.value.replace(/[^0-9]/g, "");
      if (!val || isNaN(val)) {
        e.target.value = "";
        updateOtpValue();
        return;
      }

      let idx = 0;
      let el = e.target;

      if (val.length === 0) {
        updateOtpValue();
        return;
      }

      if (isInProgress.value) return;
      isInProgress.value = true;

      if (val.length === 1) {
        el.value = val;
        el = el?.nextElementSibling ?? null;
      }

      if (val.length === 2) {
        el.value = val.substring(1, 2);
        el = el?.nextElementSibling ?? null;
      }

      while (val.length > 2 && idx < val.length && !!el) {
        el.value = val.substring(idx, idx + 1);
        el = el?.nextElementSibling ?? null;
        idx++;
      }

      el?.focus();
      isInProgress.value = false;
      updateOtpValue();
    };

    const updateOtpValue = () => {
      otpValue.value.forEach((v, i) => {
        otpValue.value[i] = inputEls.value[i]?.value || "";
      });
    };

    const addInputEls = (el) => {
      if (el && !inputEls.value.includes(el)) {
        inputEls.value.push(el);
      }
    };
    const submitHandler = () => {
      retrieveDataHandler(otpValueAfterJoin.value);
    };
    const cancelHandler = () => {
      router.push({ name: "index" });
    };

    return {
      otpValue,
      isInProgress,
      inputEls,
      otpValueAfterJoin,
      isOtpValueSomeEmpty,
      handleInputElsInput,
      addInputEls,
      title,
      OTP_LENGTH,
      submitHandler,
      cancelHandler,
      isError,
      isErrorMessage,
    };
  },
};
</script>
<style scoped>
@media only screen and (max-width: 430px) {
  .wrapper {
    min-height: 100px;
    margin-top: 10px;
  }
  .logo {
    margin: 15px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo img {
    height: 35%;
    width: 35%;
  }
  /**OTP */

  .container {
    width: 80%;
    margin: auto;
    padding: 28px 0;
  }

  .container .header {
    text-align: center;
    margin-bottom: 24px;
    color: var(--secondary-color);
  }

  .container .input-wrapper {
    width: 100%;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
  }

  .container .input-wrapper input {
    width: 2em;
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
  }

  .container h5 {
    text-align: center;
    margin-top: 24px;
    color: var(--semi-dark-color);
    font-weight: 500;
  }
  .action-button {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .action-button input {
    min-height: 50px;
  }
  .submit {
    background-color: var(--primary-color);
  }
  .cancel {
    background-color: var(--secondary-color);
  }
  .error {
    background-color: rgb(252, 206, 206);
    color: var(--secondary-color);
    font-weight: 500;
  }
  .footer {
    margin-top: 30px;
  }
  .footer span {
    background-color: rgb(255, 53, 53);
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    color: var(--light-color);
  }
}
</style>
