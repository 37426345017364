import { inject, reactive, toRefs } from "vue";
import validationAlert from "@/utils/reusables/SwalAlert";
export default function retrieveDataHandler() {
  const axios = inject("$axios");
  const router = inject("$router");
  const store = inject("$store");
  const state = reactive({
    isError: false,
    isErrorMessage: null,
  });
  const { errorHandler } = validationAlert();
  const retrieveDataHandler = async (val) => {
    try {
      let reference = Number(val);
      const { data } = await axios.get(`/meta/api/clients/`, {
        params: {
          id: reference,
        },
      });
      console.log(data);
      if (data.status !== 400) {
        let agentCode = data[0].agentcode;
        router.push(`/qr-scanner/application-form/deped/${agentCode}`);
        let dataItem = data[0];
        store.commit("retrievedActivate", dataItem);
        state.isErrorMessage = null;
        state.isError = false;
      } else {
        state.isErrorMessage = data.message;
        state.isError = true;
      }
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
  };
  return {
    ...toRefs(state),
    retrieveDataHandler,
    errorHandler,
  };
}
