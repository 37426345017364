import { inject, reactive, toRefs } from "vue";
import validationAlert from "@/utils/reusables/SwalAlert";
export default function adminHandler() {
  const axios = inject("$axios");
  const store = inject("$store");
  const Swal = inject("$swal");
  const router = inject("$router");
  const state = reactive({
    loading: false,
    isLoading: false,
    countdown: 30,
  });

  const { errorHandler } = validationAlert();
  const createdClients = async (payment, amountInput) => {
    try {
      /*const timer = setInterval(() => {
        state.countdown--;
        if (state.countdown === 0) {
          clearInterval(timer);
          state.loading = false;
          state.countdown = 30; // Reset countdown
        }
      }, 1000);*/

      let transactionID;
      store.state.formData.paymentmethod = payment;
      let formDetails = store.state.formData;

      if (!store.state.ifRetrievedData) {
        const formData = new FormData();
        formData.append(
          "file",
          store.state.formData.uploadFile,
          store.state.formData.ModFilename
        );
        let result = await axios.post(`/meta/api/local/files`, formData);
        if (result.status === 200) {
          const { data } = await axios.post("/meta/api/clients", formDetails);
          if (data.status) {
            state.loading = false;
            transactionID = data.data.id;
            Swal.fire({
              position: "bottom",
              icon: "success",
              title:
                amountInput === 0
                  ? "Zero payment indicates that you did not pay any amount to your agent and your application is pending."
                  : "Your application has been processing.",
              showConfirmButton: true,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                if (amountInput) {
                  return router.push(
                    `/deped/alert/transaction/temp/meta/api/raw/${transactionID}`
                  );
                } else {
                  return router.push(
                    `/deped/alert/transaction/temp/meta/api/raw/hold/${data.data.referenceNumber}`
                  );
                }
              }
            });
          }
        }
      } else {
        Swal.fire({
          position: "bottom",
          icon: "success",
          title: "Your application has been processing.",
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            if (amountInput) {
              let transactionID = store.state.transactionId;
              return router.push(
                `/deped/alert/transaction/temp/meta/api/raw/${transactionID}`
              );
            }
          }
        });
      }
    } catch (error) {
      state.loading = false;
      errorHandler(error);
    }
  };
  const updateClients = async (id, reference) => {
    try {
      await axios.put(`/meta/api/clients/${id}`, {
        status: "paid",
        reference: reference,
      });
      Swal.fire({
        title: "Payment Paid",
        text: "The application has been completed.",
        icon: "success",
      });
      await axios.get(`/meta/api/auth/production?id=${id}`);
    } catch (error) {
      errorHandler(error);
    }
  };
  const updateTransaction = async (id, reference) => {
    try {
      let result = await axios.post(`/meta/api/auth/production?id=${id}`);
      if (result) {
        let res = await axios.put(`/meta/api/clients/${id}`, {
          status: "paid",
          reference: reference,
        });
        if (res) {
          state.isLoading = false;
          Swal.fire({
            title: "Payment Successful",
            text: "The application has been completed.",
            icon: "success",
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              return router.push(`/`);
            }
          });
        }
      }
    } catch (error) {
      state.isLoading = false;
      errorHandler(error);
    }
  };

  return {
    ...toRefs(state),
    createdClients,
    updateClients,
    updateTransaction,
    errorHandler,
  };
}
