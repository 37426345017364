<template>
  <div class="wrapper">
    <div class="top-header">
      <slot name="header"></slot>
    </div>
    <div class="container">
      <div class="col-one">
        <slot name="profile"></slot>
      </div>
      <div class="col-two">
        <div class="action">
          <div class="btn">
            <input
              type="button"
              value="Ask for Refund?"
              @click="refundPortal()"
            />
          </div>
          <div class="text">
            <helpSVG />Need help? <callSVG />Please call (0926 8293 199)
          </div>
        </div>
      </div>
      <div class="col-three">
        <slot name="virtual"></slot>
      </div>
      <div class="col-four">
        <slot name="plan"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import validationAlert from "@/utils/reusables/SwalAlert";
import helpSVG from "@/components/svgs/helpSVG.vue";
import callSVG from "@/components/svgs/callSVG.vue";
export default {
  components: {
    helpSVG,
    callSVG,
  },
  setup() {
    const { refundPortal } = validationAlert();
    return {
      refundPortal,
    };
  },
};
</script>
<style scoped>
.wrapper {
  max-width: 1360px;
  margin: auto;
}
.top-header {
  margin: 40px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo img {
  height: 50px;
  width: auto;
}
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 330px;
  gap: 25px;
}
.col-one {
  padding: 30px;
  background-color: #fff;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  border-radius: 10px;
}
.col-two {
  padding: 30px;
  background-color: #fff;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  border-radius: 10px;
}
.col-three {
  padding: 30px;
  background-color: #fff;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  border-radius: 10px;
  height: 480px;
}
.col-four {
  padding: 30px;
  background-color: #fff;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  border-radius: 10px;
}
.col-two .action {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.col-two .action .text {
  display: flex;
  align-items: center;
  gap: 4px;
}
.action .btn input {
  background-color: #fa7319;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  border-radius: 10px;
  color: #fff;
  height: 50px;
  padding: 0px 20px;
}

.btn-preview a {
  background-color: #fa7319;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  border-radius: 10px;
  color: #fff;
  padding: 10px 30px;
  text-decoration: none;
  position: relative;
  bottom: 190px;
}
input:focus {
  outline: none !important;
  border: 1px solid #fa7319;
  box-shadow: 0 0 2px #dbe9f8;
}
.btn-preview {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-details {
  color: #fff;
  position: relative;
  bottom: 240px;
  left: 54px;
  line-height: 32px;
}
.card-details h1 {
  font-size: 38px;
  font-family: Bebas Neue;
}
.card-details h3 {
  font-family: Bebas Neue;
  font-size: 25px;
}
.qr {
  position: relative;
  bottom: 370px;
  left: 435px;
}
.qr img {
  height: 90px;
  width: 90px;
}

@media only screen and (max-width: 430px) {
  .container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
  .top-header {
    margin: 20px 18px;
  }
  .col-one,
  .col-two,
  .col-three,
  .col-four {
    margin: 0px 14px;
  }

  .card {
    width: 100%;
    height: 100%;
  }
  .col-three {
    height: 250px;
  }
}
</style>
