<template>
  <div class="wrapper">
    <div class="container">
      <div class="divider">
        <div class="header-icon">
          <greetingSVG id="svg" />
          <div class="header-message">
            <h2>You're almost done!</h2>
            <p style="text-indent: 8%">
              Thank you. Your <b>reference number is {{ referenceNumber }}</b
              >. All information entered will be saved and may be retrieved upon
              payment of Premium.
            </p>
          </div>

          <div class="btn-confirmation-code">
            <input
              type="submit"
              value="Back to homepage"
              @click="submitHandler()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import greetingSVG from "@/components/svgs/greetingSVG.vue";
import { useRoute } from "vue-router";
import { ref, inject, computed } from "vue";
export default {
  components: {
    greetingSVG,
  },
  setup() {
    const route = useRoute();
    const router = inject("$router");
    const transactionID = route.params.id;
    const confirmationCode = ref(null);
    const referenceNumber = computed(() => {
      let reference = transactionID;
      return reference;
    });

    const submitHandler = () => {
      return router.push(`/`);
    };

    return {
      confirmationCode,
      transactionID,
      referenceNumber,
      submitHandler,
    };
  },
};
</script>
<style scoped>
.divider {
  display: flex;
  flex-direction: column;
}
.header-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.header-message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 20px;
}
.header-message h2 {
  padding: 10px 0px;
}
.header-message p {
  line-height: 24px;
}
#svg {
  margin: 30px 0px;
  height: 180px;
  width: 180px;
}
.input-confirmation-code {
  margin: 10px 0px;
}
.input-confirmation-code input {
  height: 60px;
  font-size: 27px;
  text-align: center;
}
.btn-confirmation-code input {
  height: 50px;
  width: 100%;
  font-size: 18px;
  text-align: center;
}
</style>
