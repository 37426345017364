import { reactive, toRefs, inject } from "vue";
import validationAlert from "@/utils/reusables/SwalAlert";
export default function applicationData() {
  const axios = inject("$axios");
  const state = reactive({
    agentInfo: [],
    planInfo: [],
  });
  const { errorHandler } = validationAlert();
  const fetchAgentItems = async (val) => {
    try {
      let agentcode = val;
      const { data } = await axios.get(`/meta/api/agents/${agentcode}`);
      return data;
    } catch (error) {
      errorHandler(error);
    }
  };
  const fetchPlanItems = async (val) => {
    try {
      let plancode = val;
      const { data } = await axios.get(`/meta/api/plans/${plancode}`);
      return data;
    } catch (error) {
      errorHandler(error);
    }
  };
  const fetchAgentDataFile = async (filename) => {
    try {
      let name = filename;
      return await axios.get(`meta/api/local/files/agent/${name}`);
    } catch (error) {
      errorHandler(error);
    }
  };
  const fetchDiscountList = async () => {
    try {
      const { data } = await axios.get(`/meta/api/discount`);
      return data;
    } catch (error) {
      errorHandler(error);
    }
  };
  return {
    ...toRefs(state),
    fetchAgentItems,
    fetchPlanItems,
    fetchAgentDataFile,
    fetchDiscountList,
    errorHandler,
  };
}
