<template>
  <div class="wrapper">
    <div class="container">
      <div class="divider">
        <div class="header-title">
          <h2>Terms and Condition</h2>
        </div>
        <div class="content">
          <p>
            1. Hospitalization coverage pertains to diseases/illnesses that are
            not included in the List of Exclusions.
            <br />2. Our company abides by the standard HMO regulations
            concerning Exclusions, Pre-existing Conditions and Contestability
            clause;<br />3. HMO coverage will take effect from the date of
            premium payment and ends according to coverage plan<br />4. Purchase
            of the product is bound by age requirement • between ages 18 and 64
            years old. • Exit age is 65; <br />5. Plan coverages/benefits are
            non-transferrable.

            <br />
            <br />
            <b>Payment Terms </b>
            <br />
            <br />
            1. The amount of HMO premium paid in cash, debit/credit card should
            be entered in the IWC electronic designed system otherwise, such
            premium payment will not be recognize by IWC.
            <br />2.
            <span style="font-weight: 600">
              Members will be given
              <b style="color: red">fifteen (15) days free look period </b>
              which, he/she may review the Plan's provisions and if no benefit
              have been availed of, the member may opt to have his/her
              membership cancelled and have the amount paid in cash or charged
              on his/her credit/debit card refunded </span
            ><br />
            <br />
          </p>
          <p>
            To continue please click the
            <span class="link" @click="pecTermCondtion(), (isRead = true)"
              >Pre-Existing Conditions (PEC) and Exclusions</span
            >
          </p>
          <div
            class="multiples"
            id="preview"
            v-for="(items, index) in item"
            :key="index"
          >
            <img v-if="items.imageUrl" :src="items.imageUrl" />
            <removeSVG @click="removeHandler(index)" />
          </div>
          <div class="confirmation">
            <input v-model="isConfirm" type="checkbox" />
            <span>
              I hereby declare that I have read, understand and agree to the
              terms and condition.
            </span>
          </div>
        </div>
        <div class="pagination">
          <div class="btn-action">
            <input
              type="button"
              value="Back"
              @click="$emit('actionPage', 'ClientInformation')"
            />
            <input type="button" value="Next" @click="nextHandler()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import validationAlert from "@/utils/reusables/SwalAlert";
import removeSVG from "@/components/svgs/removeSVG.vue";
import { ref, reactive, inject, onMounted } from "vue";
export default {
  components: {
    removeSVG,
  },
  setup(props, { emit }) {
    const { termCondtionPortal, pecTermCondtion } = validationAlert();
    const store = inject("$store");
    const isConfirm = ref(false);
    const isRead = ref(false);
    const fileData = ref(null);
    const uploadFile = ref(null);
    const profileURL = ref(null);
    const fileItems = ref([]);
    const getProfile = ref(null);
    const item = reactive([]);
    onMounted(() => {
      if (store.state.ifRetrievedData) {
        isConfirm.value = true;
        isRead.value = true;
      } else {
        isConfirm.value = false;
        isRead.value = false;
      }
    });
    const nextHandler = () => {
      if (isConfirm.value && isRead.value) {
        store.state.uploadMultiple = item;
        emit("actionPage", "PaymentConfirmation");
      } else if (!isRead.value) {
        termCondtionPortal("link");
      } else if (!isConfirm.value) {
        termCondtionPortal("checkbox");
      } else {
        termCondtionPortal("both");
      }
    };
    const selectFile = (e) => {
      if (fileData.value.files.length > 0) {
        let itemCounts = fileData.value.files.length;

        for (let i = 0; i < itemCounts; i++) {
          fileItems.value.push(fileData.value.files[i]);
          const file = e.target.files[i];
          item.image = file;
          item.imageUrl = URL.createObjectURL(file);
          let filenameMod = `${file.lastModified}.${file.name
            .split(".")
            .pop()}`;
          item.push({
            image: file,
            imageUrl: URL.createObjectURL(file),
            filename: filenameMod,
          });
        }
      } else {
        getProfile.value = null;
      }
    };
    const removeHandler = (index) => {
      item.splice(index, 1);
      item.length - 1;
    };
    return {
      isConfirm,
      isRead,
      nextHandler,
      termCondtionPortal,
      pecTermCondtion,
      selectFile,
      removeHandler,
      fileData,
      uploadFile,
      profileURL,
      fileItems,
      getProfile,
      item,
    };
  },
};
</script>
<style scoped>
.divider {
  display: flex;
  flex-direction: column;
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.content p {
  padding: 20px 25px;
}
.content .confirmation {
  padding: 20px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
}
.content .confirmation input {
  width: 42px;
  height: 42px;
}
.content .confirmation span {
  font-weight: 700;
}
.pagination {
  margin: 20px 30px;
}
.pagination .btn-action {
  display: flex;
  justify-content: space-between;
}
.link {
  color: rgb(42, 42, 231);
  font-weight: 600;
}
.multiples {
  display: flex;
  flex-direction: row !important ;
  gap: 20px;
  align-items: center;
}
#preview img {
  height: 180px;
  width: 180px;
}
input#uploadMultiple {
  color: rgba(0, 0, 0, 0);
  display: inline-block;
  width: 100%;
  padding: 120px 0 0 0;
  height: 100px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url("https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-ios7-cloud-upload-outline-512.png")
    center center no-repeat;
  border-radius: 20px;
  background-size: 60px 60px;
}
</style>
